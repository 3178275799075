import React from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import OutsideClickDetector from '../components/OutsideClickDetector';

import facebookLogo from '!raw-loader!../images/facebook.svg';
import linkedinLogo from '!raw-loader!../images/linkedin.svg';
import twitterLogo from '!raw-loader!../images/twitter.svg';


export default class Share extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shown: false };

        this.copy = this.copy.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
    }

    render() {
        const { children, className, text, title, url } = this.props;
        const encodedText = encodeURIComponent(title);
        const encodedUrl = encodeURIComponent(url);

        return (
            <div className={classnames('share', className)}>
                <button className="permalink" onClick={this.show}>{children}</button>
                <CSSTransition in={this.state.shown} mountOnEnter={true} unmountOnExit={true}
                               addEndListener={(node, done) => node.addEventListener('transitionend', done)}
                               classNames="tooltip">
                    <OutsideClickDetector className="tooltip" onClick={this.hide}>
                        {text}<br/>
                        <input className="url" inputMode="none" readOnly value={url} autoFocus
                            ref={(el) => { this._shareUrlEl = el; }} />
                        <button className="copy" onClick={this.copy}>copy</button>
                        <button className="close" onClick={this.hide}>close</button>
                        <div className="social">
                            <a className="linkedin share-link" target="_blank"
                               href={`https://www.linkedin.com/shareArticle?url=${encodedUrl}&mini=true&title=${encodedText}`} dangerouslySetInnerHTML={{__html: linkedinLogo}}/>
                            <a className="twitter share-link" target="_blank"
                               href={`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`} dangerouslySetInnerHTML={{__html: twitterLogo}}/>
                            <a className="facebook share-link" target="_blank"
                               href={`https://www.facebook.com/sharer.php?u=${encodedUrl}`} dangerouslySetInnerHTML={{__html: facebookLogo}}/>
                        </div>
                    </OutsideClickDetector>
                </CSSTransition>
            </div>
        );
    }

    componentDidUpdate(props, state) {
        if (!state.shown && this.state.shown) {
            this._shareUrlEl.select();
        }
    }

    copy() {
        this._shareUrlEl.focus();
        this._shareUrlEl.select();
        document.execCommand('copy');
    }

    show() { this.setState({shown: true}); }
    hide() { this.setState({shown: false}); }
}

