import React from 'react';
import { Link } from 'gatsby';
import slugify from '../lib/slugify';

import AnchorLink from './AnchorLink';
import Outline from './Outline';


export default ({post, previous, next, maxDepth, active}) => {
    const series = post.fields.series;
    const titleSlug = slugify(post.frontmatter.title);
    return (
        <section className="quick-nav">
            { series ?
                <h1><Link to={`${series.fields.slug}#${titleSlug}`}>{series.name} series</Link></h1>
                :
                <h1>Quick navigation</h1>
            }
            { previous && <Link to={previous.fields.slug} state={{ animate: 'previous' }}
                                 className="prev" rel="prev" title="Previous post">
                <svg className="arrow" viewBox="0 0 256 256" preserveAspectRatio="none">
                    <path d="M0,128L256,0v64L128,128l128,64v64"/>
                </svg>
            </Link> }
            { next && <Link to={next.fields.slug} state={{ animate: 'next' }}
                            className="next" rel="next" title="Next post">
                <svg className="arrow" viewBox="0 0 256 256" preserveAspectRatio="none">
                    <path d="M256,128L0,0v64L128,128l-128,64v64"/>
                </svg>
            </Link> }
            <AnchorLink className="back-to-top" href="#">top</AnchorLink>
            <Outline headings={post.headings} maxDepth={maxDepth} active={active} />
        </section>
    );
}
