import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import classnames from 'classnames';

import AnchorLink from '../components/AnchorLink';
import Bio from '../components/Bio';
import Content from '../components/Content';
import QuickNav from '../components/QuickNav';
import Share from '../components/Share';
import Tag from '../components/Tag';


export default class PostPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentWaypoint: null,
        };
        this.onContentWaypoint = this.onContentWaypoint.bind(this);
    }

    render() {
        const siteMetadata = this.props.data.site.siteMetadata;

        const post = this.props.data.markdownRemark;
        const fullUrl = (siteMetadata.siteUrl + post.fields.slug).replace(/([^:])\/\//g, '$1/');
        const { previous, next, seriesPrevious, seriesNext } = this.props.pageContext;

        const isPost = !post.fields.isPage;
        const image = post.image && post.image.original.src;
        const series = post.series;
        const html = post.html.split('<p>--- excerpt ---</p>').pop().trim();
        const excerpt = post.excerpt.replace(/ *\n+ */g, ' ').trim();

        return (
        <>
            <Helmet title={`${post.frontmatter.title} | ${siteMetadata.title}`}>
                <html lang="en" />
                <link rel="canonical" href={fullUrl} />
                <meta name="description" value={excerpt} />
                <meta name="author" value={siteMetadata.author.fullName} />
                <meta property="og:type" content={ isPost ? "article" : "website" } />
                <meta property="og:title" content={post.frontmatter.title} />
                <meta property="og:site_name" content={siteMetadata.title} />
                <meta property="og:description" content={excerpt} />
                { image  && <meta property="og:image" content={image} />}
                { isPost && <meta property="article:published_time" content={post.frontmatter.isoDate} /> }
                { isPost && <meta property="article:author" content={siteMetadata.author.url} /> }
                { isPost && <meta property="twitter:card" content="summary" /> }
                <meta property="twitter:site:id" content={siteMetadata.twitterId} />
                <link rel="alternate" type="application/json+oembed" href="/oembed.json" />
            </Helmet>
            <main className="post-page">
                <article className={classnames("post", post.frontmatter.classname)}>
                    <h1>{post.frontmatter.title}</h1>
                    { isPost && <div className="metadata">
                        { series && <div className="breadcrumbs">
                            <Link className="home" to="/">Home</Link>{' » '}
                            <Link to={series.fields.slug}>{series.fullName || series.name} series</Link>
                        </div> }
                        <time className="publish-date" dateTime={post.frontmatter.date}>{post.frontmatter.formattedDate}</time>
                        {post.tags.length > 0 &&
                        <ul className="tag-list">{post.tags.map((tag) => (
                            <li key={tag.slug}><Tag value={tag} rel={true}/></li>
                        ))}</ul>}
                    </div> }
                    <Content html={html} onWaypoint={this.onContentWaypoint}/>
                    <footer>
                        <Share title={post.frontmatter.title} url={fullUrl}
                               text="Share a link to this article:">share</Share>
                        <div className="author">by {siteMetadata.author.fullName}</div>
                        <AnchorLink className="back-to-top" href="#">back to top</AnchorLink>
                    </footer>
                </article>
                { (previous || next) && <nav className="bottom-links">
                    {previous && (
                        <div className="previous article-link">
                            <Link to={previous.fields.slug} rel="prev" title="Previous article">
                                <svg className="arrow" viewBox="0 0 256 256" preserveAspectRatio="none">
                                    <path d="M0,128L256,0v64L128,128l128,64v64"/>
                                </svg>
                                {previous.frontmatter.title}
                            </Link>
                        </div>
                    )}
                    {next && (
                        <div className="next article-link">
                            <Link to={next.fields.slug} rel="next" title="Next article">
                                {next.frontmatter.title}
                                <svg className="arrow" viewBox="0 0 256 256" preserveAspectRatio="none">
                                    <path d="M256,128L0,0v64L128,128l-128,64v64"/>
                                </svg>
                            </Link>
                        </div>
                    )}
                </nav> }
            </main>
            <aside>
                <Bio person={siteMetadata.author}/>
                <div className="sticky">
                    <QuickNav post={post} previous={seriesPrevious} next={seriesNext}
                              maxDepth={2} active={this.state.currentWaypoint}/>
                </div>
            </aside>
        </>
        )
    }

    onContentWaypoint(id) {
        this.setState({currentWaypoint: id});
    }
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        twitterId
        author { ...BioPerson url }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      fields {
        isPage
        slug
      }
      frontmatter {
        title
        classname
        date
        formattedDate: date(formatString: "MMMM DD, YYYY")
        isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ss+00:00")
      }
      headings {
          value
          depth
      }
      image { original { src } }
      series { name fullName fields { slug } }
      tags: children { ...Tag }
    }
  }
`
