import React from 'react';
import classnames from 'classnames';

import AnchorLink from './AnchorLink';
import slugify from '../lib/slugify';

let uniqueId = 1;   // we want new ids everytime to prevent re-use

function popAndBuildNode(stack, refs) {
    const node = stack.pop();
    const parent = stack[stack.length-1];
    const register = (el) => {
        node.activeOn.forEach((slug) => {refs[slug] = el;});
    };

    parent.children.push(
        <li key={uniqueId++}>
            <AnchorLink ref={register} className="heading" href={`#${node.slug}`}>
                { node.text }
            </AnchorLink>
            { node.children.length > 0 && <ol>{ node.children }</ol>}
        </li>
    );
}


export default class Outline extends React.Component {
    render() {
        const { className, headings, active, maxDepth } = this.props;
        const stack = [{ children: [] }];
        const refs = {};

        headings.forEach((heading) => {
            const slug = slugify(heading.value);

            // If heading is too deep, skip it and lift active state to ascendant
            if (heading.depth > maxDepth) {
                stack[stack.length-1].activeOn.push(slug);
                return;
            }

            // Build any complete node
            while (stack.length > heading.depth) { popAndBuildNode(stack, refs); }

            // Add current node to stack
            stack.push({
                text: heading.value,
                slug: slug,
                children: [],
                activeOn: [slug]
            });
        });
        while (stack.length > 1) { popAndBuildNode(stack, refs); }

        this._refs = refs;
        return (
            <ol className={classnames('outline', className)}>{ stack.pop().children }</ol>
        );
    }

    componentDidUpdate() {
        this.updateActiveItem(this.props.active);
    }

    shouldComponentUpdate(props) {
        if (this.props.className !== props.className ||
            this.props.headings !== props.headings ||
            this.props.maxDepth !== props.maxDepth) {
            return true;
        }
        if (this.props.active !== props.active) {
            this.updateActiveItem(props.active, this.props.active);
        }
        return false;
    }

    updateActiveItem(newActive, oldActive) {
        const oldEl = this._refs[oldActive];
        const newEl = this._refs[newActive];
        if (oldEl) { oldEl.classList.remove('active'); }
        if (newEl) { newEl.classList.add('active'); }
    }
}
