import React from 'react';


export default class OutsideClickDetector extends React.Component {
    constructor(props) {
        super(props)
        this.onGlobalClick = this.onGlobalClick.bind(this);
    }

    render() {
        return (
            <div ref={(el) => { this._element = el; }} className={this.props.className}>
                {this.props.children}
            </div>
        );
    }

    addGlobalHandler() { document.addEventListener('mousedown', this.onGlobalClick); }
    removeGlobalHandler() { document.removeEventListener('mousedown', this.onGlobalClick); }

    componentDidMount() { this.addGlobalHandler(); }
    componentWillUnmount() { this.removeGlobalHandler(); }

    onGlobalClick(evt) {
        if (!this._element.contains(evt.target)) {
            if (this.props.onClick) { this.props.onClick(evt); }
        }
    }
}
